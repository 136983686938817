@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
}

.container {
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  background-color: #22223b;
}

.container div {
  width: 100%;
  height: 100vh;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  color: white;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

.welcome div {
  font-size: 0.3em;
  flex-direction: column;
  background-image: url(../public/welcome-background.png);
  background-repeat:no-repeat;
  background-position: cover;
  background-size: 100%;
}

.welcome p {
  line-height: 100px;
}

.about div {
  font-size: 20px;
  display: inline-block;
  text-align: center;
}

.about h1 {
  line-height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.skills div {
  font-size: 0.3em;
  display: inline-block;
  text-align: center;
  padding: 150px;
  padding-left: 250px;
  padding-right: 250px;
}

.skills h1 {
  line-height: 100px;
}

.skills p {
  padding: 80px;
}

.skills img {
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  margin: 10px;
  padding: 10px;
}

.projects div {
  font-size: 0.3em;
  display: inline-block;
  text-align: center;
}

.projects h1 {
  line-height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.projects p {
  font-size: smaller;
  padding: 10px;
  padding-top: 20px;
  padding-left: 125px;
  padding-right: 125px;
}

.project-img {
  height: 380px;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

#diagram {
  filter: none;
}

.projects button {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.about p {
  padding-left: 125px;
  padding-right: 125px;
}

.about img {
  height: 450px;
  border: 0;
  margin-top: 50px;
}

.icon {
  height: 100px;
  width: 100px;
  padding: 20px;
}

.contact div {
  font-size: 0.3em;
  display: inline-block;
  text-align: center;
  height: 400px;
  line-height: 60px;
}

.contact svg {
  padding: 5px;
  font-size: 125%;
}

#feedback {
  font-size: 15px;
}

button {
  font-size: 1em;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.widgets-container {
  position: fixed;
  top: 60px;
  right: 30px;
  z-index: 9999;
  padding: 10px;
}

.widget-icon svg {
  font-size: 260%;
  color: white;
}

#widget-button {
  position: fixed;
  top: 20px;
  right: 37px;
  font-size: 125%;
  transition: filter 300ms;
}

#widget-button :hover {
  color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

.navbar :hover {
  color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

nav {
  color: white;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.navbar {
  padding-top: 10px;
  font-size: 110%;
  color: white;
}

.contact h1 {
  margin-top: 20px;
}

.contact svg {
  color: white;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: white;
}

.navbar p {
  font-size: 1em;
  margin-top: 15.5px;
  margin-bottom: 15.5px;
}

@media only screen and (max-width: 600px) {
  .welcome div {
    background-size: 300%;
  }

  .project-img {
    height: 150px;
    margin-top: 0px;
  }

  .about div {
    padding: 20px;
  }

  .skills div {
    padding: 0px;
  }

  .skills img {
    margin: 0px;
    margin-bottom: 20px;
    padding: 0px;
  }

  .skills h1 {
    padding-bottom: 50px;
    padding-top: 20px;
  }

  .icon {
    height: 50px;
    padding: 0px;
  }

  .skills p {
    padding: 50px;
    padding-top: 90px;
    font-size: 75%;
  }

  .projects h1 {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .projects p {
    padding: 20px;
    padding-bottom: 0;
    font-size: 75%;
  }

  .projects button {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .about h1 {
    margin-top: 0;
  }
  .about p {
    font-size: smaller;
    padding-left: 0;
    padding-right: 0;
  }

  .about img {
    height: 220px;
    border: 0;
    margin: 0;
    margin-top: 30px;
  }

  #widget-button {
    top: 625px;
  }

  .widgets-container {
    background-color:rgba(34, 34, 59, 0.5);
  }
}
